@import "../config/config.scss";

// Menu Btn

.menu-btn {
  position: absolute;
  top: 0;
  right: 20px;
  top: 15px;
  z-index: 99;
  cursor: pointer;
  background: darken($primary-color, 5%);
  width: 45px;
  height: 39px;
  border-radius: 2px;
  display: grid;
  justify-content: center;
  align-content: center;
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
}
@media (max-width: 740px) {
  .menu-btn {
    top: 15px;
  }
}
.menu-btn:hover {
  background: lighten($secondary-color, 10%);
  width: 48px;
  height: 42px;
}
.menu-btn:hover .btn-line-wrapper .btn-line {
  background: darken($primary-color, 10%);
  width: 30px;
  height: 4px;
}
.menu-btn .btn-line-wrapper .btn-line {
  width: 28px;
  height: 3px;
  margin-bottom: 5px;
  background: $secondary-color;
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
}
.menu-btn .btn-line-wrapper {
  margin-bottom: -3px;
  position: relative;
}
.menu-btn.close {
  transform: rotate(180deg);
  position: fixed;
  top: 35px;
  width: 39px;
  height: 39px;
  margin-right: 4px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.menu-btn.close .btn-line-wrapper .btn-line:nth-child(2) {
  opacity: 0;
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
}
.menu-btn.close .btn-line:nth-child(1) {
  opacity: 0;
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
}
.menu-btn.close .btn-line:nth-child(3) {
  transition: 0.2s ease-out;
  opacity: 0;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
}
.menu-btn.close .btn-line-wrapper {
  display: grid;
  position: absolute;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
}
.menu-btn.close .btn-line-wrapper .btn-line-close1 {
  position: absolute;
  width: 33px;
  background: darken(#f4f4f4, 30%);
  height: 3px;
  content: "";
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.menu-btn.close .btn-line-wrapper .btn-line-close2 {
  position: absolute;
  width: 33px;
  height: 3px;
  background: darken(#f4f4f4, 30%);
  content: "";
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
.menu-btn.close:hover {
  transform: rotate(180deg);
  width: 42px;
  height: 42px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.menu-btn.close:hover .btn-line-wrapper .btn-line-close1 {
  width: 35px;
  height: 4px;
  background: darken(#f4f4f4, 30%);
}
.menu-btn.close:hover .btn-line-wrapper .btn-line-close2 {
  width: 35px;
  height: 4px;
  background: darken(#f4f4f4, 30%);
}

// Menu

.menu-overlay {
  opacity: 0;
  transition: 0.9s ease-out;
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: darken($primary-color, 5%);
  display: none;
  -webkit-transition: 0.9s ease-out;
  -moz-transition: 0.9s ease-out;
  -ms-transition: 0.9s ease-out;
  -o-transition: 0.9s ease-out;
}
.menu-overlay.show {
  opacity: 1;
  z-index: 98;
  display: initial;
  display: grid;
}
.menu-overlay ul {
  list-style: none;
  font-size: 30px;
  align-self: center;
}
@media (max-width: 740px) {
  .menu-overlay ul {
    font-size: 25px;
  }
}
.menu-overlay ul li {
  padding: 30px;
  color: darken($secondary-color, 0%);

  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
}
.menu-overlay ul li:hover {
  background-color: darken($primary-color, 10%);
  color: lighten($secondary-color, 15%);
}
.menu-overlay ul .link {
  text-decoration: none;
  color: inherit;
}
nav ul li {
  cursor: pointer;
}

nav ul .country {
  display: block;
  font-size: 25px;
  transition: all 0.3s ease-out;
  overflow: hidden;
  height: 0px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}
@media (max-width: 740px) {
  nav ul .country {
    font-size: 25px;
  }
}
nav ul .country.show {
  display: block;
  height: auto;
}

// search icon
.search.animated.bounceInLeft {
  animation-delay: 900ms;
}
.search {
  position: fixed;
  width: 105px;
  height: 30px;
  left: 35px;
  bottom: 20px;
  z-index: 99;
  background: darken($primary-color, 7%);
  // display: initial;
  display: none;
  -webkit-box-shadow: 2px 2px 2px 0px darken($primary-color, 10%);
  -moz-box-shadow: 2px 2px 2px 0px darken($primary-color, 10%);
  box-shadow: 2px 2px 2px 0px darken($primary-color, 10%);
}
.search.none {
  display: none;
}
.searchtext {
  position: absolute;
  top: 5px;
  left: 30px;
  width: 62px;
  height: 18px;
  background: darken($primary-color, 7%);
  color: lighten($secondary-color, 35%);
  border: 0;
}
.circle {
  position: absolute;
  border: 2px solid lighten($secondary-color, 35%);
  border-radius: 100%;
  width: 8px;
  height: 8px;
  top: 8px;
  left: 5px;
  cursor: pointer;
}
.stiel {
  position: absolute;
  border: 1px solid lighten($secondary-color, 35%);
  width: 5px;
  height: 0px;
  bottom: 9px;
  transform: rotate(50deg);
  left: 13px;
  cursor: pointer;
}

// countryOverlay
.countryOverlay {
  opacity: 0;
  transition: 0.9s ease-out;
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: darken($primary-color, 7%);
  display: none;
}
.countryOverlay.show {
  opacity: 1;
  z-index: 98;
  display: initial;
  display: grid;
}
.countryOverlay ul {
  list-style: none;
  font-size: 30px;
  align-self: center;
}
@media (max-width: 740px) {
  .countryOverlay ul {
    font-size: 25px;
  }
}
.countryOverlay ul li {
  padding: 30px;
  color: darken($secondary-color, 0%);

  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
}
.countryOverlay ul li:hover {
  background-color: darken($primary-color, 10%);
  color: darken($primary-color, 30%);
}
.countryOverlay ul .link {
  text-decoration: none;
  color: inherit;
}
.countryOverlay ul li {
  cursor: pointer;
}
.arrowBox {
  position: absolute;
  background: darken($primary-color, 7%);
  left: 7vw;
  top: 3vh;
  padding: 10px;
  font-size: 25px;
  color: darken($secondary-color, 7%);
  cursor: pointer;
  animation-delay: 400ms;
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
}
.arrowBox .after,
.arrowBox .before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
}

.arrowBox .after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: darken($secondary-color, 7%);
  border-width: 10px;
  margin-top: -10px;
}
.arrowBox .before {
  border-color: rgba(194, 225, 245, 0);
  border-right-color: lighten($fourth-color, 20%);
  border-width: 16px;
  margin-top: -16px;
}
.arrowBox:hover {
  background-color: lighten($fourth-color, 15%);
}
.arrowBox:hover .after {
  border-right-color: lighten($fourth-color, 15%);
}
.arrowBox:hover .before {
  border-right-color: lighten($fourth-color, 15%);
}
