@import "../config/config.scss";

header {
  width: 100%;
  font-family: "Oswald", sans-serif;
  background: $secondary-color;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  z-index: 3;
  position: relative;
}

header .upper {
  text-align: center;
  background: $primary-color;
}
header .logo {
  color: $primary-color;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;
  position: relative;
}
header .logo .wrapper {
  display: inline-block;
}
header .logo .wrapper .link {
  text-decoration: none;
  color: inherit;
}
