@import "../config/config.scss";

// Blog List

.countryList .imageWrapper {
  position: relative;
  text-align: center;
}
.countryList .imageWrapper .countryHeader {
  font-size: 4vw;
  position: absolute;
  top: 1vh;
  left: 1vw;
  background: rgba(253, 253, 255, 0.74);
  padding: 1vw;
}

.countryList .imageWrapper img {
  width: 100%;
  height: auto;
}

.countryList .blogEntry .link {
  text-decoration: none;
  color: $secondary-color;
}
.countryList .blogEntry h2 {
  margin-bottom: 0;
}
.countryList .blogEntry h2:hover {
  color: lighten($secondary-color, 50%);
}
.countryList .blogEntry .date {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 15px;
}
