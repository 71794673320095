@import "./components/config/config.scss";

.container {
  margin: 0 auto;
  width: 80%;
  color: $secondary-color;
  background: $primary-color;
}
@media (max-width: 580px) {
  .container {
    margin: 0 auto;
    width: 97%;
  }
}
