@import "../config/config.scss";

.mapWrapper {
  position: relative;
}
.mapWrapper .map {
  width: 100%;
  margin: 0 auto;
  background: #bfd8dc;
}
.map .mapFrame {
  width: 100%;
  height: auto;
}
.mapWrapper .marker {
  cursor: pointer;
}
.Home main {
  clear: both;
}
.mapOverlay {
  position: absolute;
  margin: auto;
  top: 10px;
  right: 10px;
  width: 200px;
  height: 200px;
  border: 5px solid $primary-color;
  border-radius: 5px;
  display: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}
.mapOverlay.shown {
  display: initial;
}
@media (max-width: 850px) {
  .mapOverlay.shown {
    display: none;
  }
}

.mapOverlay .overlayWrapper {
  width: 100%;
  height: 100%;
  display: grid;
}
.mapOverlay .overlayWrapper .overlayImage {
  position: absolute;
  width: 100%;
  height: 100%;
}
.mapOverlay .overlayWrapper::before {
  background: rgba(253, 253, 255, 0.2);
  content: "";
  z-index: 2;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.mapOverlay .overlayWrapper .innerBox {
  align-self: baseline;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  background: rgba(253, 253, 255, 0.93);
  color: $secondary-color;
  font-family: "Oswald", sans-serif;
  z-index: 2;
}
.mapOverlay .overlayWrapper .innerBox h2 {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}
.mapOverlay .overlayWrapper .innerBox .date {
  font-size: 12px;
  margin-bottom: 10px;
}
.mapOverlay .overlayWrapper .innerBox .linkWrapper {
  background: $secondary-color;
  float: right;
  padding: 2px;
  margin-top: 5px;
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  -webkit-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.75);
}
.mapOverlay .overlayWrapper .innerBox .linkWrapper:hover {
  background: darken($primary-color, 3%);
  float: right;
  padding: 2px;
  margin-top: 5px;
}
.mapOverlay .overlayWrapper .innerBox .linkWrapper .link {
  cursor: pointer;
  text-decoration: none;
  color: $primary-color;
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
}
.mapOverlay .overlayWrapper .innerBox .linkWrapper .link:hover {
  color: $secondary-color;
}

// Filler
main .filler {
  width: 100%;
  font-family: "Oswald", sans-serif;
  height: 80px;
  text-align: center;
  vertical-align: center;
  line-height: 80px;
  font-size: 25px;
  color: $secondary-color;
}
@media (max-width: 450px) {
  main .filler {
    font-size: 20px;
  }
}
@media (max-width: 320px) {
  main .filler {
    font-size: 17px;
  }
}

// Profile
main .profileWrapper {
  width: 100%;
  background: lighten(gray, 45%);
  margin-bottom: 38px;
  overflow: hidden;
  margin-top: 5px;
  border-top: 2px solid $secondary-color;
  border-bottom: 2px solid $secondary-color;
  color: $secondary-color;
}
main .profileWrapper .container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 0 auto;
  width: 80%;
  background: lighten(gray, 45%);
}
@media (max-width: 840px) {
  main .profileWrapper .container {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    text-align: center;
  }
  main .profileWrapper .image {
    height: 50vh;
  }
  main .profileWrapper .description p {
    margin: 0;
  }
  main .profileWrapper .image {
    padding-top: 0;
  }
}
main .profileWrapper .description {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  margin-top: 20px;
  margin-bottom: 28px;
}
main .profileWrapper .description h1 {
  margin-top: 10px;
  font-family: "Oswald", sans-serif;
}
main .profileWrapper .image {
  display: grid;
  justify-items: center;
  align-items: center;
}

main .profileWrapper .image img {
  max-width: 75%;
  max-height: 75%;
  border-radius: 500px;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  display: inline-block;
  margin-top: 5%;
  margin-bottom: 5%;
  -ms-border-radius: 500px;
  -o-border-radius: 500px;
}
main .profileWrapper .image img::before {
  background: rgba(253, 253, 255, 0.7);
  content: "";
  z-index: 2;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
// Blog List
.Home .blogEntry .link {
  text-decoration: none;
  color: $secondary-color;
}
.Home .blogEntry h2 {
  margin-bottom: 0;
}
.Home .blogEntry h2:hover {
  color: lighten($secondary-color, 50%);
}
.Home .blogEntry .date {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 15px;
}
