@import "../config/config.scss";

.Blog {
  font-size: 20px;
  line-height: 30px;
  color: darken($secondary-color, 60%);
  padding: 40px;
  margin-top: 20px;
  font-family: "Lato", sans-serif;
}

@media (max-width: 1200px) {
  .Blog .container {
    margin: 0 auto;
    width: 97%;
  }
}
@media (max-width: 880px) {
  .Blog {
    padding: 0;
  }
  .Blog .container {
    margin: 0 auto;
    width: 97%;
  }
}
@media (max-width: 580px) {
  .Blog {
    padding: 0;
  }
}
.Blog .blogImage {
  width: 100%;
  height: auto;
}
.Blog .videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  overflow: hidden;
}

.Blog .videoContainer iframe,
.Blog .videoContainer object,
.Blog .videoContainer embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.Blog .date {
  font-size: 15px;
  line-height: 10px;
  text-align: center;
}
.Blog h1 {
  text-align: center;
  line-height: 50px;
}

.Blog .commentsWrapper .comment {
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;
}
.Blog .commentsWrapper .comment .name {
  color: #3399e6;
  font-size: 25px;
  margin-right: 10px;
}
.Blog .commentsWrapper .comment .date {
  font-size: 15px;
  text-align: left;
}
